import { ReactElement, useEffect, useState } from 'react';
import { Alert, Col, Menu, notification, Row } from 'antd';
import CcxComponentProps from '../../../../core/CcxComponent';
import Service from '../../../../types/Service';
import AppGridTable from '../../../ccx/common/AppGridTable';
import styles from './DataStoreServices.module.less';
import ServiceConnectionInformation from './ServiceConnectionInformation';
import ServiceInformation from './ServiceInformation';
import ServiceStatus from './ServiceStatus';
import { useParams } from 'react-router';
import useDataStoreServices from '../../../../core/hooks/useDataStoreServices';
import LazyLoader from '../../../LazyLoader';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import AppEmpty from '../../../AppEmpty';
import Helpers from '../../../../services/Helpers';
import { Dropdown } from 'antd';
import { Button } from 'antd';
import CcxContextualMenuIcon from '../../../ccx/icons/CcxContextualMenuIcon';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';
import Job, { STATUS_RUNNING } from '../../../../types/Job';
import DotLabelStatus from '../../../ccx/common/DotLabelStatus';
import { Tooltip } from 'antd';
import SmallContainer from '../../../ccx/common/SmallContainer';
import PromoteReplicaButton from './PromoteReplicaButton';
import ServiceResources from './ServiceResources';
import ExtendNodeStorageModal from './DataStoreExtendStorageModal';
import ScaleNodeModal from './DataStoreScaleNodeModal';
import { getNonErroredJobs, sortNodesByRole } from '../../../../core/helpers';
import { useAppDispatch } from '../../../../redux/hooks';
import DataStoreOverviewAccessToServices from '../overview/DataStoreOverviewAccessToServices';
import RepairNodeButton from './RepairNodeButton';
import ConnectionAssistant from './ConnectionAssistant';
import DataStoreNodeConfiguration from './DataStoreNodeConfiguration';
import { getCurrentDataStore } from '../../../../slices/datastore.slice';
import { ReloadOutlined } from '@ant-design/icons';
import useReplicaLag from '../../../../core/hooks/useReplicaLag';
import ProvisionService from '../../../../services/ProvisionService';
import CcxIconInfoCircleTwoTone from '../../../ccx/icons/CcxIconInfoCircleTwoTone';
import RebootNodeButton from './RebootNodeButton';

interface UrlProps {
    dataStoreUuid?: string;
    projectUuid: string;
}

interface Props extends CcxComponentProps {
    dataStore?: DeploymentsItem;
    setDbCount: Function;
    switchTab: Function;
}

interface ActionMenuProps {
    service: Service;
    onClick: (e: any) => void;
    showPromote: boolean;
    showRepair: boolean | undefined;
    disabled: boolean;
}

function ActionMenu({
    service,
    onClick,
    showPromote,
    showRepair,
    disabled,
}: ActionMenuProps) {
    return (
        <Menu onClick={onClick}>
            {showPromote && (
                <Menu.Item
                    key="promote"
                    className={styles.PromoteReplicaButton}
                    disabled={disabled}
                >
                    <PromoteReplicaButton service={service} />
                </Menu.Item>
            )}
            {showRepair && (
                <Menu.Item key="repair" disabled={disabled}>
                    <RepairNodeButton service={service} />
                </Menu.Item>
            )}
            {!showRepair && (
                <Menu.Item
                    key="reboot"
                    disabled={disabled}
                    className={styles.RebootButton}
                >
                    <RebootNodeButton service={service} />
                </Menu.Item>
            )}{' '}
        </Menu>
    );
}

function DataStoreServices({
    switchTab,
    dataStore,
    setDbCount,
    testId = 'DataStoreServices',
}: Props): ReactElement {
    const { dataStoreUuid, projectUuid } = useParams<UrlProps>();
    const currentPath = `/projects/${projectUuid}/data-stores/${dataStoreUuid}/services`;
    const dispatch = useAppDispatch();
    const [isScalingModalVisible, setIsScalingModalVisible] =
        useState<boolean>(false);
    const { services, refresh, loading, updating } = useDataStoreServices({
        dataStoreUuid,
    });
    const [isNodesAvailable, setIsNodesAvailable] = useState<boolean>(false);

    const { jobs, refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid,
    });
    const { replicaLag, refresh: refreshReplicaLag } = useReplicaLag({
        dataStoreUuid,
    });
    const [intervalId, setIntervalId] = useState<any>([]);

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [dbRows, setDbRows] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dbColumns = [
        { title: 'Node information', span: 4 },
        { title: 'Role / Permissions', span: 5 },
        { title: 'Resources', span: 13 },
    ];

    const lbColumns = [
        { title: 'Node information', span: 5 },
        { title: 'Status', span: 3 },
        { title: 'Connection information', span: 15 },
    ];

    const startStoreInterval = () => {
        const id = setInterval(async () => {
            if (dataStoreUuid) {
                dispatch(getCurrentDataStore({ dataStoreUuid }));
            }
        }, 10000);

        setIntervalId((prevIds: any) => [...prevIds, id]);
    };

    const clearStoreInterval = () => {
        intervalId.forEach((id: any) => clearInterval(id));
        setIntervalId([]);
    };

    useEffect(() => {
        return () => {
            clearStoreInterval();
        };
    }, []);

    useEffect(() => {
        const hasRunningJob = jobs?.some(
            (job: Job) => job.status === STATUS_RUNNING
        );

        if (!hasRunningJob) {
            clearStoreInterval();
        }
    }, [jobs]);

    useEffect(() => {
        if (window.location.pathname !== currentPath) {
            clearStoreInterval();
        }
    }, [window.location.pathname]);

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 20000;
        const interval = setInterval(async () => {
            refresh && (await refresh());
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setDbCount && setDbCount(dbRows.length);
    }, [dbRows]);

    useEffect(() => {
        if (dataStore) {
            refreshReplicaLag();
        }
    }, [dataStore]);

    useEffect(() => {
        if (services && dataStore) {
            const sortedDbServices = sortNodesByRole(services.dbServices);
            const tempDBRows = sortedDbServices?.map((s: Service) => {
                function handleMenuOptionClick(e: any) {
                    if (e.key === 'settings') {
                    }
                }

                const showPromote =
                    s.isRoleReplica() &&
                    !s.isStatusError() &&
                    !s.isStatusWarning();

                const showRepair =
                    !(
                        dataStore?.isMSSQL() &&
                        dataStore.clusterType.toLowerCase() === 'mssql_single'
                    ) &&
                    dataStore?.clusterStatus?.isStatusStartedOrDegraded() &&
                    (s.isStatusError() ||
                        s.getStatus() === Service.CMON_STATUS_UNKNOWN);

                const menuDisabled =
                    (!(showPromote || showRepair) &&
                        dataStore?.isSafespring()) ||
                    pendingJobs?.length > 0 ||
                    !dataStore?.isOperable();

                const isNodeJobRunning = pendingJobs.find((j: Job) =>
                    j?.data?.hostname?.includes(s?.getHostname())
                );

                return (
                    dataStore && {
                        borderAlert: s.isStatusError(),
                        statusOverlayText: s.getStatus(),
                        columns: [
                            {
                                content: (
                                    <ServiceInformation
                                        dataStore={dataStore}
                                        service={s}
                                        testId={`${testId}${s.getServiceUuid()}ServiceInformation`}
                                        replicaLag={
                                            s.isRoleReplica() &&
                                            replicaLag &&
                                            replicaLag[s.getHostUuid()] &&
                                            replicaLag[s.getHostUuid()]
                                                .replicaLag > 0
                                                ? replicaLag[s.getHostUuid()]
                                                      .replicaLag
                                                : undefined
                                        }
                                    />
                                ),
                                span: 4,
                            },
                            {
                                content: (
                                    <div>
                                        {`${Helpers.capitalizeFirstLetter(
                                            s.getRoleName()
                                        )} / ${Helpers.capitalizeFirstLetter(
                                            s.getPermission()
                                        )}`}
                                    </div>
                                ),
                                span: 5,
                            },
                            {
                                content: (
                                    <ServiceResources
                                        service={s}
                                        dataStore={dataStore}
                                    />
                                ),
                                span: 13,
                            },
                            {
                                align: 'center',
                                content: (
                                    <Dropdown
                                        className={
                                            styles.DataStoresDropdownMenu
                                        }
                                        data-testid={`${testId}${s.getServiceUuid()}Menu`}
                                        overlay={
                                            <ActionMenu
                                                disabled={
                                                    pendingJobs?.length > 0 ||
                                                    !dataStore?.isOperable()
                                                }
                                                service={s}
                                                onClick={handleMenuOptionClick}
                                                showPromote={showPromote}
                                                showRepair={showRepair}
                                            />
                                        }
                                        placement="bottomRight"
                                        trigger={['click']}
                                        disabled={menuDisabled}
                                    >
                                        <Button
                                            data-testid={`${testId}${s.getServiceUuid()}MenuButton`}
                                            icon={
                                                <CcxContextualMenuIcon
                                                    testId={`${testId}${s.getServiceUuid()}MenuButtonIcon`}
                                                />
                                            }
                                        />
                                    </Dropdown>
                                ),
                                span: 2,
                            },
                        ],
                        disabled: s.isStarting(),
                    }
                );
            });

            if (jobs) {
                const runningJobs = jobs?.filter(
                    (job: Job) => job.isStatusRunning() && job.isAddNode()
                );

                const statusComponent = (
                    <Tooltip
                        placement="bottom"
                        title={'Deployment is running and will be ready soon'}
                    >
                        <DotLabelStatus
                            type="info"
                            label={'Deploying...'}
                            glow={updating}
                            testId={`${testId}StatusComponent`}
                        />
                    </Tooltip>
                );

                const runningRows = runningJobs?.map((job: Job) => {
                    return (
                        dataStore && {
                            borderAlert: false,
                            statusOverlayText: '',
                            columns: [
                                {
                                    content: (
                                        <ServiceInformation
                                            dataStore={dataStore}
                                            service={services.dbServices[0]}
                                            deploying={true}
                                        />
                                    ),
                                    span: 4,
                                },
                                {
                                    content: (
                                        <SmallContainer
                                            testId={testId}
                                            topChildren={statusComponent}
                                        />
                                    ),
                                    span: 5,
                                },
                                {
                                    content: <LazyLoader type="row" rows={1} />,
                                    span: 13,
                                },
                                {
                                    align: 'center',
                                    content: (
                                        <Dropdown
                                            className={
                                                styles.DataStoresDropdownMenu
                                            }
                                            data-testid={`${testId}${services.dbServices[0].getServiceUuid()}Menu`}
                                            overlay={null as any}
                                            placement="bottomRight"
                                            trigger={['click']}
                                        >
                                            <Button
                                                data-testid={`${testId}${services.dbServices[0].getServiceUuid()}MenuButton`}
                                                icon={
                                                    <CcxContextualMenuIcon
                                                        testId={`${testId}${services.dbServices[0].getServiceUuid()}MenuButtonIcon`}
                                                    />
                                                }
                                            />
                                        </Dropdown>
                                    ),
                                    span: 2,
                                },
                            ],
                            disabled: true,
                        }
                    );
                });
                setDbRows([...runningRows, ...tempDBRows]);
            } else {
                setDbRows(tempDBRows);
            }

            setIsNodesAvailable(true);
        }
    }, [services, dataStore, updating, jobs, replicaLag]);

    const pendingJobs = getNonErroredJobs(jobs);

    return (
        <div className={styles.DataStoreServices} data-testid={testId}>
            <main>
                {pendingJobs?.length > 0 && (
                    <Alert
                        className={styles.DataStoreServicesJobRunningAlert}
                        message={
                            <span>
                                Jobs are in progress. Please wait for it to
                                finish before making any changes. For status
                                check{' '}
                                <span
                                    className={
                                        styles.DataStoreServicesSwitchToEventViewerLink
                                    }
                                    onClick={() => switchTab('eventViewer')}
                                >
                                    Event Viewer
                                </span>
                            </span>
                        }
                        showIcon
                        type="info"
                    />
                )}

                {dataStore?.replica_url ||
                dataStore?.primary_url ? undefined : (
                    <ConnectionAssistant
                        dataStoreUuid={dataStoreUuid}
                        currentDeployment={dataStore}
                    />
                )}

                {dataStore && (
                    <ScaleNodeModal
                        datastore={dataStore}
                        services={services}
                        nodes={dbRows}
                        pendingJobs={pendingJobs}
                        refresh={refresh}
                        clearStoreInterval={clearStoreInterval}
                        startStoreInterval={startStoreInterval}
                        isButtonDisabled={isButtonDisabled}
                        setIsButtonDisabled={setIsButtonDisabled}
                        isScalingModalVisible={isScalingModalVisible}
                        setIsScalingModalVisible={setIsScalingModalVisible}
                    />
                )}
                {dataStore?.hasStorage() && (
                    <ExtendNodeStorageModal
                        datastore={dataStore!}
                        pendingJobs={pendingJobs}
                        nodes={dbRows}
                        refresh={refresh}
                        clearStoreInterval={clearStoreInterval}
                        startStoreInterval={startStoreInterval}
                        isButtonDisabled={isButtonDisabled}
                        setIsButtonDisabled={setIsButtonDisabled}
                    />
                )}

                {(dataStore?.replica_url || dataStore?.primary_url) && (
                    <DataStoreOverviewAccessToServices
                        dataStore={dataStore}
                        services={services}
                    />
                )}

                <Row
                    justify={'space-between'}
                    align={'middle'}
                    className={styles.AppTableHeadingBar}
                >
                    <Col style={{ paddingBottom: 8 }}>
                        <h4>Nodes</h4>
                    </Col>
                    <Col style={{ paddingBottom: 8 }}>
                        <DataStoreNodeConfiguration
                            setIsScalingModalVisible={setIsScalingModalVisible}
                            clearStoreInterval={clearStoreInterval}
                            dataStore={dataStore}
                            services={services}
                        />
                    </Col>
                </Row>

                {loading && !isNodesAvailable && <LazyLoader type="row" />}

                <div className={styles.AppGridTableWrapper}>
                    {isNodesAvailable && dbRows?.length > 0 ? (
                        <AppGridTable
                            columns={dbColumns}
                            rows={dbRows}
                            testId={`${testId}Databases`}
                        />
                    ) : (
                        !loading && (
                            <AppEmpty message="Nodes information is not available for this datastore." />
                        )
                    )}
                </div>
            </main>
        </div>
    );
}

export default DataStoreServices;
